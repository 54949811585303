<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="font-weight-bolder">
          {{ $t("message.chart.projects") }}
        </b-card-title>
        <b-card-sub-title class="mt-25">
          {{ $t("message.chart.projectsDesc") }}
        </b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker 
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>
       -->
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="bar"
        :height="chart.height"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import store from "@/store";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    vSelect,
  },

  data() {
    return {
      chart: {},
      series: [],
      chartOptions: {},

      chartLabels: [],
      chartValues: [],
      chartValues2: [],

      chartTimestamp: "",

      userData: store.state.user.userData,
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadChartData();
    },
  },

  async created() {
    await this.loadChartData();
  },

  methods: {
    async loadChartData() {
      var vm = this;

      await axios
        .get(`${URL_API}chart/projects/all`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          var string = response.data.chartInfo;

          if (string != null) {
            var data = JSON.parse(string); // Json to object

            this.chartLabels = Object.keys(data[0]);
            var evolution = Object.values(data[0]);
            this.chartValues2 = Object.values(data[1]);

            this.chartValues = [];
            for (var i = 0; i < this.chartValues2.length; i++) {
              this.chartValues.push(
                Math.trunc((evolution[i] * this.chartValues2[i]) / 100)
              );
            }

            this.chartTimestamp = response.data.timestamp;
          }
        });

      this.chart = {
        height: vm.getAutoHeight(vm.chartLabels.length),
      };

      this.series = [
        {
          name: vm.getColLabel("evolution"),
          data: this.chartValues,
        },
        {
          name: vm.getColLabel("estimatedTime"),
          data: this.chartValues2,
        },
      ];

      this.chartOptions = {
        colors: ["#db9833", "#2a6592"],
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        title: {
          text: vm.localeDate(this.chartTimestamp),
          align: "right",
          margin: 10,
          offsetX: 0,
          offsetY: 25,
          floating: false,
          style: {
            fontSize: "12px",
            fontWeight: "normal",
          },
        },

        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.3,
            opacityFrom: 0.8,
            opacityTo: 0.9,
            stops: [0, 50, 100],
          },
        },

        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            if (seriesIndex === 1) {
              return value + "h";
            } else {
              return value;
            }
          },

          textAnchor: "middle",
          distributed: false,
          offsetX: -25,
          offsetY: 0,
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
        },

        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              if (seriesIndex === 1) {
                return value + "h";
              } else {
                return value;
              }
            },
          },
        },
        xaxis: {
          categories: this.chartLabels,
          labels: {
            show: true,
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              if (seriesIndex === 1) {
                return value + "h";
              } else {
                return value;
              }
            },
          },
        },
      };
    },

    getAutoHeight(value) {
      if (value === 0) {
        return 600;
      } else if (value === 1) {
        return 2 * 100;
      } else {
        return value * 120;
      }
    },

    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    getColLabel(colName) {
      return this.$i18n.t("message.chart." + colName);
    },

    currencyValue(value) {
      return this.$i18n.n(value, "currency");
    },
  },
};
</script>
